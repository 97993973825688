<template>
  <div>
    <v-row align="center" class="justify-center">
      <v-btn
            align='center'
            text
            color="primary" @click="print">Print
      </v-btn>
    </v-row>
    <v-row id="printMe">
      <v-card
        class="mx-auto mt-0"
        width="1000"
      >
        <v-card-title class="justify-center">
            <span class="title font-weight-light justify-center">{{remedies[0]['resident']['fullName']}}</span>
        </v-card-title>
      </v-card>
      <v-card
        class="mx-auto my-2"
        max-width="1000"
        width="1000"
      >
        <v-card-text>
            <div class="text--primary">
              <p><b>Remedy Name:</b> {{remedies[0]['name']}}</p>
              <p><b>Balance:</b> {{remedies[0]['balance']}}</p>
              <p><b>Comment: </b>{{remedies[0]['comment']}}</p><br>
              <p><b>Quantity Given:</b> {{remedies[0]['quantityGiven']}}</p><br>
              <p><b>Witness:</b> {{remedies[0]['witness']}}</p><br>
            </div>
        </v-card-text>
        <img height="50" width="50" src="../../assets/logo.png">
      </v-card>
    </v-row>
    </div>
</template>

<script>
import remedyApi from '../../services/api/RemedyApi';
export default {
    name: 'remediesDetail',
    data() {
        return {
            remedies: '',
            residentId: '',
            remediesId: ''
        }
    },
    computed: {

    },
    created() {
        this.residentId = this.$route.params.residentId;
        this.remediesId = this.$route.params.remediesId;
        this.get_registered_remedy_list();
    },
    methods: {
       get_registered_remedy_list () {
          this.loading = true
          return new Promise((resolve, reject) => {
              remedyApi.getRegisteredRemedy({page: '', count:'', id:this.residentId, q:'', orderBy:'', order: ''}) 
                .then(remedies => {
                    this.remedies = remedies.result.filter( item => item.id === this.remediesId )
                    this.totalRemedies = this.remedies.length
                })
                .catch(error => {
                    return reject(error)
                })
          })
        },
        print () {
          this.$htmlToPaper('printMe');
        }
    }
}
</script>

<style scoped>

</style>